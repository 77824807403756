<template>
  <div class="custom-upload-container">
    <a-upload-dragger :fileList="innerFileList" name="file" :showUploadList="props.showUploadList" :maxCount="limitNum"
      @drop="handleFileDrop" id="commonUpload" @change="handleFileChange" :beforeUpload="handleBeforeUpload"
      :customRequest="customRequestFn" :accept="props.accept" :multiple="props.multiple">
      <upload-container>
        <upload-icons :iconUrl="props.iconUrl" :iconStyle="props.iconStyle" />
        <upload-text>{{ placeHolder }}</upload-text>
        <upload-credittip style="margin-top:5px;">{{ creditTip }}</upload-credittip>
      </upload-container>
    </a-upload-dragger>
  </div>
</template>

<script setup>
import {
  ref,
  defineEmits,
  defineProps,
  watchEffect,
  watch,
  onMounted,
  computed,
  onUnmounted,
} from "vue";
import proIcon from "@/assets/pro_icon.svg";
import UploadContainer from "./components/upload-container.vue";
import UploadCredittip from "./components/upload-creditip.vue";
import UploadIcons from "./components/upload-icons.vue";
import UploadText from "./components/upload-text.vue";
import { message } from "ant-design-vue";
import { useSideStore } from "@/store";
import { postFileToUrl } from "@/utils/eventTracking.js"
const userStore = useSideStore();

const props = defineProps({
  /** 双向绑定的文件列表 */
  fileList: {
    type: Array,
    default: () => [],
  },
  /** 单个筛选项配置 */
  setFileList: {
    type: Function,
    default: () => ({}),
  },
  /** 所有筛选项配置 */
  multiple: {
    type: Boolean,
    default: false,
  },
  /** 设置form值 */
  accept: {
    type: String,
    default: ".JPG,.jpg,.png,.PNG,.webp,.WEBP,.jpeg,.JPEG",
  },
  showUploadList: {
    type: Boolean,
    default: false,
  },
  limitNum: {
    type: Number,
    default: 20
  },
  iconUrl: {
    type: String,
  },
  iconStyle: {
    type: Object,
    default: () => {
      return {};
    },
  },
  placeHolder: {
    type: String,
    default: "Upload an image to start",
  },
  creditTip: {
    type: String,
    defalut: " ",
  },
  customRequest: {
    type: Function,
  },

  getFileListLength: {
    type: Function,
  },
  onBeforeUpload: {
    type: Function,
  },
  reUpload: {
    type: Boolean
  },
  videoConfig: { //视频工具类的限制配置 例如:视频高清化的{size:1GB,time:1hour}
    type: Object,
  }
});

const uploadError = ref(false);
const timer = ref(null);
const acceptFile = ref(null);
const innerFileList = ref([]);
const isSetFileList = ref(false)

watchEffect(() => {
  if (props.fileList && props.fileList.length) {
    innerFileList.value = props.fileList;
  }
});

onMounted(() => {
  //
  const commonUploadDom = document.getElementById("commonUpload");
  commonUploadDom?.removeAttribute("capture");
  //判断当前设备类型
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    // 当前设备是移动设备
    acceptFile.value = "image/*";
  } else {
    acceptFile.value = ".png,.jpg,.jpeg,.webp,.PNG,.JPG,.JPEG";
  }
});

onUnmounted(() => {
  clearInterval(timer.value);
});

const customRequestFn = ({ file, onProgress }) => {

  if (props.customRequest) {

    props.customRequest(file);

  }
};

const handleFileChange = (value) => {
  if (props.getFileListLength) {
    props.getFileListLength(value.fileList?.length)
  }

  handleBeforeUpload(value.file)
  if (uploadError.value) {
    return;
  }
  if (value.fileList.length > props.limitNum) {
    // message.warning('超出限制, 只取前10个')
  }

  value.fileList.slice(0, props.limitNum + 1)

  if (props.reUpload) {
    isSetFileList.value = false
  }
  // 上传回调
  if (props.setFileList) {

    // 只执行一次
    if (isSetFileList.value) {
      return
    }

    isSetFileList.value = true
    // console.log(value.fileList)
    storeSourceImage(value.fileList);
    props.setFileList(value.fileList, props.multiple);
  }
};
// 埋点 记录SourceImageId
const storeSourceImage = (fileList) => {
  let newSourceList = [];
  const isSource = true;
  fileList.forEach((item) => {
    newSourceList.push(item.originFileObj);
  })
  postFileToUrl(newSourceList, isSource)
}
const imageAcceptType = [
  "image/jpeg",
  "image/jpg",
  "image/webp",
  "image/png",
  "image/PNG",
  "image/JPEG",
  "image/JPG",
];

const videoAcceptType = [
  "video/mp4",
  "video/MP4"
]

const handleFileDrop = (e) => {

};

function humanFileSize (bytes, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + ' ' + units[u];
}

const dealImage = (file) => {
  const isJpgOrPng = imageAcceptType.includes(file.type);
  message.config({
    top: "80%",
    duration: 3,
    icon: () => {
      return false;
    },
  });
  if (!isJpgOrPng) {
    message.error("Only JPG , PNG , JPEG and WEBP images are supported.");
    uploadError.value = true;
  }
  return
}
const dealVideo = (file) => {
  // const isMp4 = videoAcceptType.includes(file.type);
  // if (!isMp4) {
  //   message.error("Only mp4 are supported.");
  //   uploadError.value = true;
  // } else {
  //   // validateFile(file)
  //   const sizeLimit = file.size < props.videoConfig.size;
  //   if (!sizeLimit) {
  //     uploadError.value = true;
  //     message.error(`Exceeded size limit ${humanFileSize(props.videoConfig.size)}.`);
  //   }
  // }

  const sizeLimit = file.size < props.videoConfig.size;
  if (!sizeLimit) {
    uploadError.value = true;
    message.error(`Exceeded size limit ${humanFileSize(props.videoConfig.size)}.`);
  }
  return
}



const handleBeforeUpload = (file) => {
  if (props.onBeforeUpload) {
    if (!props.onBeforeUpload()) return false;
  }

  userStore.isCollapse = true;
  //如果这个配置非空，就认识是上传视频的入口
  if (props.videoConfig) {
    dealVideo(file);
  } else {
    dealImage(file)
  }
};
</script>
<style lang="less" scoped>
.custom-upload-container {
  width: 100%;
  height: 100%;
}

.upload-content-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.ant-upload.ant-upload-drag) {
  border: none !important;
  border-radius: 6px;
  background: none;
}

:global(.ant-upload.ant-upload-drag .ant-upload) {
  padding: 0;
}

:global(.upload-container .ant-upload.ant-upload-drag .ant-upload-text) {
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background-color: #fff;
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #444444;
  border-radius: 6px;
}

.ant-upload-max-size-text {
  margin-top: 6px;
  color: #8f959e;
}

:global(.upload-container .ant-upload.ant-upload-drag .ant-upload-text:hover) {
  background-color: #f7f7f7;
}

:global(.upload-container .ant-upload.ant-upload-drag-hover .ant-upload-text) {
  background-color: #f7f7f7;
  border-radius: 6px;
}

:global(.upload-container .ant-upload.ant-upload-drag .ant-upload) {
  padding: 0;
}

.ant-upload-h2-credit {
  font-weight: 400;
}

.ant-message-notice {
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.ant-message-notice-content) {
  background: #fef1f1;
  border: 1px solid #f54a45;
  box-shadow: 0px 4px 8px rgba(31, 35, 41, 0.1);
  border-radius: 4px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 54px;
  line-height: 22px;
  padding: 0 20px;
  align-items: center;

  /* identical to box height, or 157% */

  color: #1f2329;

  .ant-message-notice-content {
    height: 100%;
    line-height: 54px;
  }
}
</style>
